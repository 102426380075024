import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'

import Wrapper from '../components/Wrapper'
import InputBox from '../components/Form/InputBox'
import CustomAlert from '../components/Alerts'
import InvisibleRepatcha from '../components/InvisibleRepatcha'

import { DOWNLOADER, FORGOTPASS } from '../data/constants'
import { loginValidations } from '../validations'

import { userLogin } from '../reducers/login'
import useForm from '../hooks/useForm'
import { loginState } from '../selectors/login'
import { getParamFromURL } from '../helpers'
import SEO from '../components/SEO'

let loginForm

const init = {
  email: '',
  password: '',
  captcha: true,
}

const LoginComponent = ({ location }) => {
  const [showPass, setShowPass] = useState(false)
  const dispatch = useDispatch()
  const {
    values: { email, password },
    errors,
    handleOnChange,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useForm(init, loginValidations, val => {
    loginForm = val
  })

  const { loading, loaded, isLoggedIn, error } = useSelector(loginState)

  const submitForm = useCallback(
    token => {
      dispatch(
        userLogin({
          ...loginForm,
          token,
        })
      )
      const { grecaptcha } = window
      grecaptcha.reset()
    },
    [isSubmitting]
  )

  const toogleEye = () => {
    setShowPass(prev => !prev)
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (location.search === '') return navigate(DOWNLOADER)
      const params = getParamFromURL(location.search, 'redirect')
      navigate(params)
    }
    return () => resetForm()
  }, [isLoggedIn])
  return (
    <>
      <SEO page="login" />
      <Wrapper title="Login">
        <div className="col-sm-6">
          <div className="cboxEraForm">
            <form onSubmit={handleSubmit}>
              <InputBox
                name="email"
                label="Email"
                type="email"
                placeholder="Email"
                value={email}
                onChangeInput={handleOnChange}
                error={errors.email}
              />
              <InputBox
                name="password"
                label="Password"
                type={showPass ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChangeInput={handleOnChange}
                error={errors.password}
              />
              <span className="password-eye">
                <i
                  role="presentation"
                  className={`fas ${showPass ? 'fa-eye-slash' : 'fa-eye'}`}
                  onClick={toogleEye}
                />
              </span>
              <div className="row">
                <InvisibleRepatcha onResolved={submitForm} />
                <div className="col-lg-6 col-md-6">
                  <Button type="submit" color="success" disabled={loading}>
                    {loading ? 'Please wait..' : 'Login'}
                  </Button>
                </div>
                <div className="col-lg-6 col-md-6 text-right mt-2">
                  <Link className="interLink" to={FORGOTPASS}>
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </form>
            {loaded && !loading && (
              <CustomAlert type={`${isLoggedIn ? 'success' : 'danger'}`}>
                <span>{isLoggedIn ? 'Success ! Redirecting..' : error}</span>
              </CustomAlert>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

LoginComponent.propTypes = {
  location: PropTypes.object.isRequired,
}

export default LoginComponent
